import React from "react";
import Slider from "react-slick";
import { HelmetDatoCms } from "gatsby-source-datocms";
// import Img from "gatsby-image";
import { graphql } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "gatsby";
import Layout from "../components/layout";
import Footer from "../components/footer";
import "../styles/blocks/sheet.sass";
export default ({ data }) => (
  <Layout>
    <article className="sheet">
      <HelmetDatoCms seo={data.datoCmsWork.seoMetaTags} />
      <div className="sheet__inner">
        <h1 className="sheet__title"><Link to="/projects"><FontAwesomeIcon className="backButton" icon={faChevronLeft} /></Link>{data.datoCmsWork.title}</h1>
        {/* <p className="sheet__lead">{data.datoCmsWork.excerpt}</p> */}
        <div className="sheet__slider">
          <Slider infinite={true} slidesToShow={1} arrows>
            {data.datoCmsWork.gallery.map(({ fluid }) => (
              <img
                alt={data.datoCmsWork.title}
                key={fluid.src}
                src={fluid.src}
              />
            ))}
          </Slider>
        </div>
        {/* <div className="sheet__body"> {data.datoCmsWork.gallery.title} </div> */}
        <div
          className="sheet__body"
          dangerouslySetInnerHTML={{
            __html: data.datoCmsWork.descriptionNode.childMarkdownRemark.html,
          }}
        />
        {/* <div className="sheet__gallery">
          <Img fluid={data.datoCmsWork.coverImage.fluid} />
        // </div> */}
      </div>
    </article>
    <Footer />
  </Layout>
);

export const query = graphql`
  query WorkQuery($slug: String!) {
    datoCmsWork(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      gallery {
        fluid(maxWidth: 200, imgixParams: { fm: "jpg", auto: "compress" }) {
          src
        }
        title
      }
      descriptionNode {
        childMarkdownRemark {
          html
        }
      }
      coverImage {
        url
        fluid(maxWidth: 600, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsSizes
        }
      }
    }
  }
`;
